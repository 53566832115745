import React, { FC, useState } from "react";
import Logo from "../../assets/images/MainLogo.svg";
import { ReactComponent as Close } from "../../assets/images/close.svg";
import { Link } from "react-scroll";

export const Header: FC = () => {
  const [menuToggle, setMenuToggle] = useState(false);

  const clickMenuToggle = () => {
    setMenuToggle(!menuToggle);
  };

  return (
    <>
      <div
        className="z-50 items-center justify-between hidden px-12 py-4 shadow lg:flex header-bg"
        id="home"
      >
        <div className="flex items-center">
          <div className="relative mr-5 logo-bg">
            <div className="logo-hover"></div>
            <svg
              className="z-10"
              width="28"
              height="32"
              viewBox="0 0 28 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.44776 17.9454C6.45109 17.9451 6.45391 17.9478 6.45391 17.9511C6.45391 17.9543 6.45648 17.9569 6.45966 17.9569H20.8824C22.0057 17.9748 23.1476 17.689 24.1814 17.0678C27.1277 15.2975 28.081 11.4739 26.3107 8.52757C25.4116 7.03116 23.9828 6.04885 22.4083 5.67637C21.4397 5.44721 20.5528 4.84708 20.0292 4.00054C18.6932 1.84071 16.2933 0.400391 13.5547 0.400391C10.2688 0.400391 7.47031 2.47399 6.41247 5.37604C6.01366 6.47013 5.05565 7.34818 3.98734 7.81166C2.1123 8.62513 0.800781 10.4929 0.800781 12.667C0.800781 15.5887 3.16926 17.9572 6.09093 17.9572C6.21085 17.9572 6.32983 17.9532 6.44776 17.9454ZM14.9473 9.35401C15.4312 9.35401 15.8236 8.96171 15.8236 8.47778C15.8236 7.99386 15.4312 7.60156 14.9473 7.60156C14.4634 7.60156 14.0711 7.99386 14.0711 8.47778C14.0711 8.96171 14.4634 9.35401 14.9473 9.35401ZM19.2314 8.476C19.2314 8.95993 18.8391 9.35223 18.3551 9.35223C17.8712 9.35223 17.4789 8.95993 17.4789 8.476C17.4789 7.99208 17.8712 7.59978 18.3551 7.59978C18.8391 7.59978 19.2314 7.99208 19.2314 8.476ZM13.8383 20.0231H15.2784C15.4854 20.0231 15.6368 20.2184 15.5852 20.4189L14.7327 23.7337C14.6811 23.9342 14.8325 24.1295 15.0395 24.1295H15.2562C15.4699 24.1295 15.6223 24.3367 15.5587 24.5407L13.5664 30.924C13.4513 31.2926 12.9021 31.174 12.9495 30.7908L13.5308 26.0821C13.5541 25.8931 13.4067 25.7264 13.2163 25.7264H12.8994C12.7013 25.7264 12.5518 25.5466 12.5879 25.3519L13.5267 20.2823C13.5545 20.1321 13.6855 20.0231 13.8383 20.0231Z"
                fill="#F1F5F9"
              />
            </svg>
          </div>
          <p className="text-base uppercase text-customLightText">
            storm force
          </p>
        </div>
        <div>
          <ul className="flex items-center uppercase">
            <Link activeClass="active" to="about" spy={true} smooth={true}>
              {" "}
              <li className="px-4 py-3 text-base text-white cursor-pointer text-opacity-70 hover:text-opacity-100">
                about
              </li>
            </Link>
            <Link activeClass="active" to="process" spy={true} smooth={true}>
              {" "}
              <li className="px-4 py-3 text-base text-white cursor-pointer text-opacity-70 hover:text-opacity-100">
                our process
              </li>
            </Link>
            {/* <Link activeClass="active" to="packages" spy={true} smooth={true}><li className="px-4 py-3 text-base text-white cursor-pointer text-opacity-70 hover:text-opacity-100">packages</li></Link> */}
            <Link activeClass="active" to="team" spy={true} smooth={true}>
              <li className="px-4 py-3 text-base text-white cursor-pointer text-opacity-70 hover:text-opacity-100">
                {" "}
                our team
              </li>
            </Link>
            <Link
              activeClass="active"
              offset={50}
              to="contact"
              spy={true}
              smooth={true}
            >
              <li>
                <button className="px-5 py-3 ml-5 text-base tracking-wider text-white uppercase rounded btn-gradient">
                  contact us
                </button>
              </li>
            </Link>
          </ul>
        </div>
      </div>
      <div className="absolute z-30 flex items-center justify-center w-full lg:hidden">
        <div className="flex flex-col items-center pt-3">
          <img className="h-12 mb-2" src={Logo} alt="Logo" />
          <p className="text-base uppercase text-customLightText">
            storm force
          </p>
        </div>
        <div
          className="absolute right-6 top-9"
          onClick={() => clickMenuToggle()}
        >
          <svg
            width="20"
            height="10"
            viewBox="0 0 20 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1"
              y1="1"
              x2="19"
              y2="1"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <line
              x1="1"
              y1="9"
              x2="19"
              y2="9"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
      {menuToggle && (
        <div className="fixed inset-0 z-50 flex flex-col items-center justify-between min-h-screen px-8 py-8 bg-black mobile-menu-bg lg:hidden">
          <div className="flex justify-center w-full">
            <img className="h-12" src={Logo} alt="Logo" />
            <Close
              className="absolute top-9 right-6"
              onClick={() => clickMenuToggle()}
            />
          </div>
          <div>
            <ul className="text-center uppercase">
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                onClick={() => clickMenuToggle()}
              >
                {" "}
                <li className="px-4 py-3 text-base text-white cursor-pointer text-opacity-70 hover:text-opacity-100">
                  about
                </li>
              </Link>
              <Link
                activeClass="active"
                to="process"
                spy={true}
                smooth={true}
                onClick={() => clickMenuToggle()}
              >
                {" "}
                <li className="px-4 py-3 text-base text-white cursor-pointer text-opacity-70 hover:text-opacity-100">
                  our process
                </li>
              </Link>
              {/* <Link
                activeClass="active"
                to="packages"
                spy={true}
                smooth={true}
                onClick={() => clickMenuToggle()}
              >
                <li className="px-4 py-3 text-base text-white cursor-pointer text-opacity-70 hover:text-opacity-100">
                  packages
                </li>
              </Link> */}
              <Link
                activeClass="active"
                to="team"
                spy={true}
                smooth={true}
                onClick={() => clickMenuToggle()}
              >
                <li className="px-4 py-3 text-base text-white cursor-pointer text-opacity-70 hover:text-opacity-100">
                  {" "}
                  our team
                </li>
              </Link>
            </ul>
          </div>
          <p className="text-base uppercase text-customLightText">
            storm force
          </p>
        </div>
      )}
    </>
  );
};
