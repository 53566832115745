import React, { FC } from "react";
import { ReactComponent as LinkedinLogo } from "../../assets/images/LinkedIn.svg";
import { ReactComponent as DownLogo } from "../../assets/images/DownIcon.svg";
import { Header } from "../Header";
import { Link } from "react-scroll";
import { isSafari } from "react-device-detect";

export const Home: FC = () => {
  const btnTextSafari = `${isSafari}`;
  return (
    <>
      <Header />
      <div className="relative flex items-center pt-20 hero-bg lg:pt-0">
        <div className="max-w-4xl px-6 mx-auto lg:px-0">
          <h1 className="mt-20 text-3xl text-white uppercase lg:text-7xl lg:mt-0 font-MetropolisExtraLight">
            your own dedicated team of senior developers, ready to build your
            app from scratch, today.
          </h1>
          <p className="mt-5 text-xl lg:text-2xl font-NoirLight">
            <span className="text-white text-opacity-60">
              We build enterprise-grade, ready-to-launch web {"&"} mobile apps
              in a fully managed turnkey process. We work only with senior
              developers to ensure that your product matches exactly what you
              had in mind. No compromises.
            </span>
          </p>
          <div className="inline-block mt-12 overflow-hidden rounded">
            <Link activeClass="active" to="contact" spy={true} smooth={true}>
              <button
                type="button"
                className={
                  btnTextSafari === "true"
                    ? "btn-gradient-border-safari rounded font-semibold uppercase text-base px-6 py-3 tracking-wider"
                    : "btn-gradient-border rounded font-semibold uppercase text-base px-6 py-3 tracking-wider"
                }
              >
                get an estimate
              </button>
            </Link>
          </div>
          {/* <p className="mt-16 tracking-wider text-white text-opacity-75 uppercase">
            stay in touch with us:
          </p>
          <a
            className="inline-block mt-10 mb-32 opacity-75 lg:mb-0 hover:opacity-100"
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/storm-force-experts"
          >
            <LinkedinLogo />
          </a> */}

          <div className="absolute flex justify-center bottom-10 inset-x-5 animate-bounce">
            <Link activeClass="active" to="about" spy={true} smooth={true}>
              {" "}
              <DownLogo className="cursor-pointer hover:text-white" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
