import React, { FC } from 'react';
import { ReactComponent as BlockQuote } from '../../assets/images/BlockQuote.svg'
interface ITestimonial {
    description: string;
    icon: string;
    name: string;
    position: string;
}

interface Props {
    data: ITestimonial
    id?: string
}

export const Testimonial: FC<Props> = ({ data, id }) => {
    return (
        <>
            <div className="bg-customDarkColor py-10 lg:py-24" id={id}>
                <div className="max-w-4xl mx-auto px-6 lg:px-0 text-center">
                    <BlockQuote className="inline" />
                    <p className="text-white text-opacity-75 text-2xl my-14 font-NoirLight">{data.description}</p>
                    <img className="h-12 rounded-full inline" src={data.icon} alt="" />
                    <p className="text-xl text-white mt-4">{data.name}</p>
                    <p className="text-xl text-white text-opacity-75">{data.position}</p>
                </div>
            </div>
        </>
    )

}