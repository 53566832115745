import { MainPage } from './pages';

function App() {
  return (
    <>
      <MainPage />
    </>
  );
}

export default App;
