import React, { FC } from "react";
import { ReactComponent as GitLogo } from "../../assets/images/Git.svg";
import { ReactComponent as TelegramLogo } from "../../assets/images/Telegram.svg";
import { ReactComponent as TwitterLogo } from "../../assets/images/Twitter.svg";
import { ReactComponent as FacebookLogo } from "../../assets/images/Facebook.svg";
import { ReactComponent as LinkedinLogo } from "../../assets/images/LinkedIn.svg";
import { Link } from "react-scroll";

export const Footer: FC = () => {
  return (
    <div className="px-4 py-10 lg:py-24 lg:px-12">
      <div className="flex flex-wrap items-center justify-center lg:justify-between">
        <div className="hidden w-80 lg:w-64 lg:block">
          <Link activeClass="active" to="home" spy={true} smooth={true}>
            <div className="relative mr-5 logo-bg">
              <div className="logo-hover"></div>
              <svg
                className="z-10"
                width="28"
                height="32"
                viewBox="0 0 28 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.44776 17.9454C6.45109 17.9451 6.45391 17.9478 6.45391 17.9511C6.45391 17.9543 6.45648 17.9569 6.45966 17.9569H20.8824C22.0057 17.9748 23.1476 17.689 24.1814 17.0678C27.1277 15.2975 28.081 11.4739 26.3107 8.52757C25.4116 7.03116 23.9828 6.04885 22.4083 5.67637C21.4397 5.44721 20.5528 4.84708 20.0292 4.00054C18.6932 1.84071 16.2933 0.400391 13.5547 0.400391C10.2688 0.400391 7.47031 2.47399 6.41247 5.37604C6.01366 6.47013 5.05565 7.34818 3.98734 7.81166C2.1123 8.62513 0.800781 10.4929 0.800781 12.667C0.800781 15.5887 3.16926 17.9572 6.09093 17.9572C6.21085 17.9572 6.32983 17.9532 6.44776 17.9454ZM14.9473 9.35401C15.4312 9.35401 15.8236 8.96171 15.8236 8.47778C15.8236 7.99386 15.4312 7.60156 14.9473 7.60156C14.4634 7.60156 14.0711 7.99386 14.0711 8.47778C14.0711 8.96171 14.4634 9.35401 14.9473 9.35401ZM19.2314 8.476C19.2314 8.95993 18.8391 9.35223 18.3551 9.35223C17.8712 9.35223 17.4789 8.95993 17.4789 8.476C17.4789 7.99208 17.8712 7.59978 18.3551 7.59978C18.8391 7.59978 19.2314 7.99208 19.2314 8.476ZM13.8383 20.0231H15.2784C15.4854 20.0231 15.6368 20.2184 15.5852 20.4189L14.7327 23.7337C14.6811 23.9342 14.8325 24.1295 15.0395 24.1295H15.2562C15.4699 24.1295 15.6223 24.3367 15.5587 24.5407L13.5664 30.924C13.4513 31.2926 12.9021 31.174 12.9495 30.7908L13.5308 26.0821C13.5541 25.8931 13.4067 25.7264 13.2163 25.7264H12.8994C12.7013 25.7264 12.5518 25.5466 12.5879 25.3519L13.5267 20.2823C13.5545 20.1321 13.6855 20.0231 13.8383 20.0231Z"
                  fill="#F1F5F9"
                />
              </svg>
            </div>
          </Link>
          {/* <p className="mt-5 text-white text-opacity-75 uppercase text-10">
            &#169; 2021 storm force llc
          </p> */}
        </div>
        <div className="flex flex-col py-3 pl-0 pr-3 text-right lg:pl-6 lg:pr-6 lg:text-left lg:border-l lg:border-customPrimaryColor">
          <Link activeClass="active" to="about" spy={true} smooth={true}>
            <span className="inline-block mb-2 text-white text-opacity-75 uppercase cursor-pointer text-10 lg:text-sm hover:text-opacity-100">
              about
            </span>
          </Link>
          <Link activeClass="active" to="process" spy={true} smooth={true}>
            <span className="inline-block mb-2 text-white text-opacity-75 uppercase cursor-pointer text-10 lg:text-sm hover:text-opacity-100">
              our process
            </span>
          </Link>
          {/* <Link activeClass="active" to="packages" spy={true} smooth={true}>
            <span className="inline-block mb-2 text-white text-opacity-75 uppercase cursor-pointer text-10 lg:text-sm hover:text-opacity-100">
              packages
            </span>
          </Link> */}
          <Link activeClass="active" to="team" spy={true} smooth={true}>
            <span className="text-white text-opacity-75 uppercase cursor-pointer text-10 lg:text-sm hover:text-opacity-100">
              our team
            </span>
          </Link>
        </div>
        <div className="justify-center flex-grow hidden space-x-6 lg:flex">
          {/* <a
            className="opacity-60 hover:opacity-100"
            style={{ marginRight: "110px" }}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/storm-force-experts/"
          >
            <LinkedinLogo className="h-6" />
          </a> */}
          {/* <a href="https://github.com/" target="_blank" rel="noreferrer"><GitLogo className="h-6" /></a>
                    <a href="https://telegram.org/" target="_blank" rel="noreferrer"><TelegramLogo className="h-6" /></a>
                    <a href="https://twitter.com/" target="_blank" rel="noreferrer"><TwitterLogo className="h-6" /></a>
                    <a href="https://www.facebook.com/" target="_blank" rel="noreferrer"><FacebookLogo className="h-6" /></a> */}
        </div>
        <div className="py-3 pl-3 border-l lg:w-72 lg:pl-6 border-customPrimaryColor">
          <div className="flex items-center mb-4">
            <div className="flex items-center justify-center mr-2 w-7">
              <svg
                width="23"
                height="16"
                viewBox="0 0 23 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.8 0H1.6C1.17565 0 0.768687 0.168571 0.468629 0.468629C0.168571 0.768687 0 1.17565 0 1.6V14.4C0 14.8243 0.168571 15.2313 0.468629 15.5314C0.768687 15.8314 1.17565 16 1.6 16H20.8C21.2243 16 21.6313 15.8314 21.9314 15.5314C22.2314 15.2313 22.4 14.8243 22.4 14.4V1.6C22.4 1.17565 22.2314 0.768687 21.9314 0.468629C21.6313 0.168571 21.2243 0 20.8 0ZM19.04 1.6L11.2 7.024L3.36 1.6H19.04ZM1.6 14.4V2.328L10.744 8.656C10.8779 8.7489 11.037 8.79869 11.2 8.79869C11.363 8.79869 11.5221 8.7489 11.656 8.656L20.8 2.328V14.4H1.6Z"
                  fill="white"
                  fillOpacity="0.75"
                />
              </svg>
            </div>
            <p className="text-white text-opacity-75 hover:text-opacity-100 text-10">
              <a href="mailto:hello@stormforce.dev">HELLO@STORMFORCE.DEV</a>
            </p>
          </div>
          <div className="flex items-center mb-3">
            <div className="flex items-center justify-center mr-2 w-7">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.6931 19.9926H17.5624C2.45258 19.1237 0.307214 6.37458 0.00732409 2.4837C-0.0168399 2.18118 0.0189265 1.87687 0.112574 1.58819C0.206221 1.29952 0.355908 1.03216 0.55306 0.801437C0.750211 0.570713 0.990951 0.381158 1.26149 0.243633C1.53202 0.106108 1.82703 0.0233159 2.12962 7.20958e-07H6.36652C6.67453 -0.000297449 6.97553 0.0918974 7.23054 0.264643C7.48554 0.437389 7.6828 0.682726 7.79676 0.968875L8.96556 3.84474C9.0781 4.12429 9.10603 4.43074 9.04588 4.72602C8.98573 5.02131 8.84015 5.29241 8.62723 5.50567L6.98937 7.1589C7.24521 8.61279 7.94147 9.953 8.98394 10.9982C10.0264 12.0434 11.3648 12.7432 12.818 13.0029L14.4866 11.3497C14.7031 11.1391 14.9767 10.9969 15.2735 10.9408C15.5702 10.8848 15.8769 10.9173 16.1552 11.0344L19.0542 12.1955C19.336 12.3131 19.5764 12.5119 19.7449 12.7666C19.9133 13.0213 20.0021 13.3204 20 13.6258V17.6858C20 18.2976 19.7569 18.8844 19.3243 19.317C18.8917 19.7496 18.3049 19.9926 17.6931 19.9926ZM2.31417 1.5379C2.11023 1.5379 1.91464 1.61891 1.77044 1.76312C1.62623 1.90732 1.54522 2.10291 1.54522 2.30684V2.36836C1.89894 6.92053 4.16733 17.6858 17.647 18.4547C17.748 18.461 17.8493 18.4472 17.945 18.4142C18.0406 18.3813 18.1289 18.3297 18.2046 18.2626C18.2803 18.1954 18.3421 18.114 18.3862 18.0229C18.4304 17.9319 18.4562 17.833 18.4621 17.7319V13.6258L15.5631 12.4646L13.3563 14.6561L12.9872 14.61C6.29732 13.7719 5.38996 7.08201 5.38996 7.0128L5.34382 6.64371L7.52763 4.43683L6.37421 1.5379H2.31417Z"
                  fill="white"
                  fillOpacity="0.75"
                />
              </svg>
            </div>
            <p className="text-white text-opacity-75 hover:text-opacity-100 text-10">
              <a href="tel:+19282681931">+1 928 268 1931</a>
            </p>
          </div>
          <div className="flex items-center">
            <div className="flex items-center justify-center mr-2 w-7">
              <svg
                width="18"
                height="26"
                viewBox="0 0 18 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 25.6274L8.44615 25.0043C8.1 24.6582 0 15.1043 0 9.35815C0 4.37354 4.01538 0.358154 9 0.358154C13.9846 0.358154 18 4.37354 18 9.35815C18 15.1043 9.9 24.6582 9.55385 25.0735L9 25.6274ZM9 1.74277C4.77692 1.74277 1.38462 5.13508 1.38462 9.35815C1.38462 13.7889 7.2 21.2658 9 23.4812C10.8 21.2658 16.6154 13.7889 16.6154 9.35815C16.6154 5.13508 13.2231 1.74277 9 1.74277Z"
                  fill="white"
                  fillOpacity="0.75"
                />
                <path
                  d="M8.99969 12.8193C7.06122 12.8193 5.53815 11.2962 5.53815 9.35778C5.53815 7.41932 7.06122 5.89624 8.99969 5.89624C10.9381 5.89624 12.4612 7.41932 12.4612 9.35778C12.4612 11.2962 10.9381 12.8193 8.99969 12.8193ZM8.99969 7.28086C7.82276 7.28086 6.92276 8.18086 6.92276 9.35778C6.92276 10.5347 7.82276 11.4347 8.99969 11.4347C10.1766 11.4347 11.0766 10.5347 11.0766 9.35778C11.0766 8.18086 10.1766 7.28086 8.99969 7.28086Z"
                  fill="white"
                  fillOpacity="0.75"
                />
              </svg>
            </div>
            <div>
              <p className="text-white text-opacity-75 uppercase text-10">
                80 broad st, 5th floor,
              </p>
              <p className="text-white text-opacity-75 uppercase text-10">
                {" "}
                new york, ny 10004
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="block lg:hidden mt-11">
        <div className="flex justify-center space-x-6">
          {/* <a className="opacity-60 hover:opacity-100" target="_blank" rel="noreferrer" href='https://www.linkedin.com/company/storm-force-experts'><LinkedinLogo className="h-6" /></a> */}
          {/* <a href="https://github.com/" target="_blank" rel="noreferrer"><GitLogo className="h-6" /></a>
                    <a href="https://telegram.org/" target="_blank" rel="noreferrer"><TelegramLogo className="h-6" /></a>
                    <a href="https://twitter.com/" target="_blank" rel="noreferrer"><TwitterLogo className="h-6" /></a>
                    <a href="https://www.facebook.com/" target="_blank" rel="noreferrer"><FacebookLogo className="h-6" /></a> */}
        </div>
        {/* <p className="mt-5 text-sm text-center text-white text-opacity-75 uppercase">&#169; 2021 storm force llc</p> */}
      </div>
    </div>
  );
};
