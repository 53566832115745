import React, { FC, useState } from "react";
import Logo from "../../assets/images/MainLogo.svg";
import EmployeeData from "../../Data/EmployeeDetail.json";
import Model from "../Model";
import { Link } from "react-scroll";

export const Team: FC = () => {
  const [data, setData] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = (key: number) => {
    setData(EmployeeData[key]);
    setOpen(true);
  };

  return (
    <div className="py-10 lg:py-24" id="team">
      <div className="px-6 mx-auto max-w-7xl lg:px-0">
        <div className="flex flex-col items-start lg:flex-row mb-7 lg:-ml-8">
          <p className="mb-3 mr-4 text-base font-black tracking-widest text-white text-opacity-75 lg:mb-0">
            03
          </p>
          <p className="pb-2 uppercase border-b-2 text-15 font-NoirMedium text-customPrimaryColor border-customGradiantFrom">
            our team
          </p>
        </div>
        <div className="mb-14 lg:mb-24">
          <p className="mb-4 text-4xl text-white lg:text-5xl">
            A team you can trust
          </p>
          <p className="text-xl text-white text-opacity-75 lg:text-2xl font-NoirLight">
            Your project gets only the absolute best. We believe in a no
            non-sense approach - having top-tier developers is the greatest and
            only way to deliver top-tier results. Have a look at some of the
            superstars we work with:
          </p>
        </div>

        {EmployeeData.map((element, key) => {
          return (
            <div
              className="flex items-center py-4 border-b border-gray-600 cursor-pointer hover:border-white opacity-80 hover:opacity-100"
              key={key}
              onClick={() => handleClick(key)}
            >
              <img
                className="mr-5 h-14 w-14 lg:h-24 lg:w-24 lg:mr-10"
                src={Logo}
                alt="Logo"
              />
              <div className="flex-grow">
                <p className="text-base text-white font-NoirMedium lg:text-2xl">
                  {element.name}
                </p>
                <p className="text-xs text-white text-opacity-75 font-NoirLight lg:text-lg">
                  {element.posistion}
                </p>
              </div>
              <svg
                className="block lg:hidden"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-5.24537e-07 12L5 7L-8.74228e-08 2L1 -3.0598e-07L8 7L0.999999 14L-5.24537e-07 12Z"
                  fill="white"
                />
              </svg>
            </div>
          );
        })}

        <div className="flex justify-center mt-24">
          <Link activeClass="active" to="contact" spy={true} smooth={true}>
            <button className="px-5 py-3 text-base tracking-wider text-white uppercase rounded btn-gradient">
              contact us
            </button>
          </Link>
        </div>
      </div>
      {open && <Model data={data} open={open} setOpen={setOpen} />}
    </div>
  );
};
