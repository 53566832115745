import React, { FC, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export const ContactUs: FC = () => {
  let [fName, setFName] = useState("");
  let [lName, setLName] = useState("");
  let [company, setCompany] = useState("");
  let [emailAddress, setEmailAddress] = useState("");
  let [jobTitle, setJobTitle] = useState("");
  let [phoneNumber, setPhoneNumber] = useState("");
  let [message, setMessage] = useState("");
  let [isLoader, setIsLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoader(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/api/sendEmail`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        first_name: fName,
        last_name: lName,
        company,
        job_title: jobTitle,
        email_address: emailAddress,
        phone_number: phoneNumber,
        message,
      },
    }).then((response) => {
      if (response.status === 200) {
        toast("Email sent successfully.");
        setFName("");
        setLName("");
        setCompany("");
        setEmailAddress("");
        setPhoneNumber("");
        setJobTitle("");
        setMessage("");

        setIsLoader(false);
      } else {
        toast("Oops.. Something went wrong, please try again");
        setIsLoader(false);
      }
    });
  };

  // <div className="py-10 lg:py-24" id="process">
  // <div className="px-6 mx-auto max-w-7xl lg:px-0">
  return (
    <div
      className="py-10 mt-12 contact-bg sm:py-24 mb-14 lg:mb-24 lg:py-24"
      // className="py-10 lg:py-24"
      id="contact"
      style={{ paddingTop: "5%", paddingBottom: "2.5%" }}
    >
      {/* <div className="px-6 mx-auto max-w-7xl lg:px-0"> */}
      <p
        className="mb-4 text-4xl text-white lg:text-5xl"
        style={{ fontSize: "2rem" }}
      >
        Reach out and get your free technical consultation!
      </p>
      {/* <div style={{ flex: 0.5 }} className="contactRow">
      </div> */}
      <div style={{ flex: 1.5 }} className="contactRow">
        <a
          href="mailto:hello@stormforce.dev"
          className="w-full px-5 py-3 mt-4 text-base tracking-wider text-white uppercase rounded btn-gradient"
        >
          Get in touch
        </a>
      </div>
      <div style={{ flex: 0.5 }} className="contactRow">
        <p
          className="text-sm text-white text-opacity-75"
          style={{ fontSize: "1.25rem" }}
        >
          You'll hear from us within 24 hours.
        </p>
      </div>
      {/* <div className="max-w-lg p-4 mx-auto rounded-lg shadow sm:bg-customFormBG sm:p-8 ">
          <div className="innerContact">
            <p className="text-xl text-customLightText sm:text-2xl">
              Contact us, or request an estimate
            </p>
            <a
              href="mailto:hello@stormforce.dev"
              className="w-full px-5 py-3 mt-4 text-base tracking-wider text-white uppercase rounded btn-gradient"
            >
              Get in touch
            </a>
            <p className="text-sm text-white text-opacity-75">
              You'll hear from us within 24 hours.
            </p>
          </div>
        </div> */}
      {/* <div className="text-center text-white" id="snackbar">
        <ToastContainer
          className="custom-toast"
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div> */}
    </div>
    // </div>
  );
};
{
  /* <form onSubmit={handleSubmit} method="POST">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div className="mt-4">
              <label
                htmlFor="first-name"
                className="block text-base font-medium text-white"
              >
                First Name *
              </label>
              <input
                placeholder="John"
                value={fName}
                required
                onChange={(e) => setFName(e.target.value)}
                type="text"
                name="first_name"
                id="first-name"
                autoComplete="given-name"
                className="block w-full px-4 py-3 mt-1 text-base text-white bg-transparent border-gray-300 rounded-md focus:ring-customPrimaryColor"
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="last-name"
                className="block text-base font-medium text-white"
              >
                Last Name *
              </label>
              <input
                placeholder="Smith"
                required
                value={lName}
                onChange={(e) => setLName(e.target.value)}
                type="text"
                name="last_name"
                id="last-name"
                autoComplete="family-name"
                className="block w-full px-4 py-3 mt-1 text-base text-white bg-transparent border-gray-300 rounded-md focus:ring-customPrimaryColor"
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="company"
                className="block text-base font-medium text-white"
              >
                Company
              </label>
              <input
                placeholder="Acme Inc."
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                type="text"
                name="company"
                id="company"
                autoComplete="company"
                className="block w-full px-4 py-3 mt-1 text-base text-white bg-transparent border-gray-300 rounded-md focus:ring-customPrimaryColor"
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="job-title"
                className="block text-base font-medium text-white"
              >
                Job Title
              </label>
              <input
                placeholder="CTO"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                type="text"
                name="job_title"
                id="job-title"
                autoComplete="job-title"
                className="block w-full px-4 py-3 mt-1 text-base text-white bg-transparent border-gray-300 rounded-md focus:ring-customPrimaryColor"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4 mt-4">
            <div className="mt-4">
              <label
                htmlFor="email-address"
                className="block text-base font-medium text-white"
              >
                Email *
              </label>
              <input
                placeholder="you@example.com"
                value={emailAddress}
                required
                onChange={(e) => setEmailAddress(e.target.value)}
                type="email"
                name="email_address"
                id="email-address"
                autoComplete="email"
                className="block w-full px-4 py-3 mt-1 text-base text-white bg-transparent border-gray-300 rounded-md focus:ring-customPrimaryColor"
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="phone-number"
                className="block text-base font-medium text-white"
              >
                Phone Number
              </label>
              <input
                placeholder="+12 123456789"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                type="number"
                name="phone_number"
                id="phone-number"
                autoComplete="phone-number"
                className="block w-full px-4 py-3 mt-1 text-base text-white bg-transparent border-gray-300 rounded-md focus:ring-customPrimaryColor"
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="message"
                className="block text-base font-medium text-white"
              >
                Message *
              </label>
              <textarea
                placeholder="Please type here"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={5}
                name="message"
                id="message"
                autoComplete="message"
                className="block w-full px-4 py-3 mt-1 text-base text-white bg-transparent border-gray-300 rounded-md appearance-none focus:ring-customPrimaryColor"
              />
            </div>

            <div className="flex items-center mt-4">
              <input
                id="agree"
                name="agree"
                type="checkbox"
                className="w-4 h-4 border-white rounded bg-customFormBG"
              />
              <p className="ml-2 text-xs text-white text-opacity-75 lg:text-sm">
                I agree to be added to the{" "}
                <span className="font-bold text-white">Storm Force</span> email
                List
              </p>
            </div>
          </div>
          <button
            className="w-full px-5 py-3 mt-4 text-base tracking-wider text-white uppercase rounded btn-gradient"
            type="submit"
          >
            {isLoader ? (
              <div className="flex items-center justify-center">
                {" "}
                <svg
                  className="w-5 h-5 text-white animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>{" "}
              </div>
            ) : (
              "submit"
            )}
          </button>
        </form> */
}
