import React, { FC } from "react";
import { Link } from "react-scroll";
import { ReactComponent as Logo1 } from "../../assets/images/Sec21.svg";
import { ReactComponent as Logo2 } from "../../assets/images/Sec22.svg";

export const About: FC = () => {
  return (
    <div className="py-16 lg:py-24" id="about">
      <div className="max-w-4xl px-6 mx-auto lg:px-0">
        <div className="flex flex-col items-start lg:flex-row mb-7 lg:-ml-8">
          <p className="mb-3 mr-4 text-base font-black tracking-widest text-white text-opacity-75 lg:mb-0">
            01
          </p>
          <p className="pb-2 uppercase border-b-2 text-15 font-NoirMedium text-customPrimaryColor border-customGradiantFrom">
            who we are
          </p>
        </div>
        <div className="lg:mb-24 mb-14">
          <p className="mb-4 text-4xl text-white lg:text-5xl">
            We build web & mobile apps from scratch
          </p>
          <p className="text-xl text-white text-opacity-75 lg:text-2xl font-NoirLight">
            Our mission at Storm Force is to provide start-ups and businesses
            with an affordable, complete and fully-managed solution for building
            web or mobile apps quickly from scratch, without sacrificing quality
            or functionality. All of our developers are senior developers with
            at least 5 years of experience, many of which worked in industry
            leading companies like Facebook. Having helped companies ranging
            from early stage start-ups to Fortune 500 listers get their product
            to production-ready standards, we pride ourself in being our
            clients' "go-to" partner for all their software development needs.
          </p>
          <div className="flex justify-center lg:mt-24 mt-14">
            <Logo1 className="h-auto w-28 lg:w-auto" />
          </div>
        </div>
        <div className="lg:mb-24 mb-14">
          <p className="mb-4 text-4xl text-white lg:text-5xl">
            The best of the best{" "}
          </p>
          <p className="text-xl text-white text-opacity-75 lg:text-2xl font-NoirLight">
            When you get a fully-managed dedicated team from us, you can be
            certain you're working with the best. We work exclusively with
            senior developers who have at least 5 years of experience in their
            areas of expertise. We choose our developers not only for their
            coding abilities, but also for their leadership and communication
            skills, creating a better development process than our competitors.
            All of our developers are leaders in their fields. Some are
            internationally recognized and have worked with some of the biggest
            companies in the world, including Facebook and other FAANG
            companies.
          </p>
          <div className="flex justify-center lg:mt-24 mt-14">
            <Logo2 className="h-auto w-44 lg:w-auto" />
          </div>
        </div>
        <p className="mb-4 text-4xl text-white lg:text-5xl">
          We give great value
        </p>
        <p className="text-xl text-white text-opacity-75 lg:text-2xl font-NoirLight">
          We pride ourselves in being a boutique agency that fosters close
          relationships with our partners. That means that, unlike competitors,
          we don't need to cover massive overhead costs like marketing, sales,
          HR, etc. For this reason, we're able to provide better results at a
          fraction of the cost, which is why we've been able to become the
          partner of choice for many early stage start-ups.
        </p>
        <div className="flex justify-center mt-14 lg:mt-24">
          <Link activeClass="active" to="contact" spy={true} smooth={true}>
            <button className="px-5 py-3 text-base tracking-wider text-white uppercase rounded btn-gradient">
              get an estimate
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
