import React, { FC, Fragment } from "react";
import { Home } from "../components/Home";
import { About } from "../components/About";
import { Testimonial } from "../components/Testimonial";
import { Process } from "../components/Process";
import { Packages } from "../components/Packages";
import { Team } from "../components/Team";
import { ContactUs } from "../components/ContactUs";
import Testimonials from "../Data/TestimonialData.json";
import { Footer } from "../components/Footer";

export const MainPage: FC = () => {
  return (
    <>
      <Home />
      <About />
      <Testimonial data={Testimonials[0]} id="wikistrat" />
      <Process />
      {/* <Packages /> */}
      <Team />
      <Testimonial data={Testimonials[1]} />
      <ContactUs />
      <Footer />
    </>
  );
};
