import React, { FC } from "react";

export const Process: FC = () => {
  return (
    <div className="py-10 lg:py-24" id="process">
      <div className="px-6 mx-auto max-w-7xl lg:px-0">
        <div className="flex flex-col items-start lg:flex-row mb-7 lg:-ml-8">
          <p className="mb-3 mr-4 text-base font-black tracking-widest text-white text-opacity-75 lg:mb-0">
            02
          </p>
          <p className="pb-2 uppercase border-b-2 text-15 font-NoirMedium text-customPrimaryColor border-customGradiantFrom">
            how it works
          </p>
        </div>
        <p className="mb-4 text-4xl text-white lg:text-5xl">Our Process</p>
        <p className="text-xl text-white text-opacity-75 lg:text-2xl font-NoirLight">
          Tell us what your project needs to do, and lie back.
        </p>

        <div
          className="relative flex flex-wrap justify-between mt-16 text-center"
          style={{ width: "100%" }}
        >
          <div className="custom-line"></div>
          <div className="block custom-line-mobile lg:hidden"></div>
          <div className="w-1/2 px-2 mb-12 lg:w-1/4 lg:px-4 lg:mb-0">
            <div className="relative flex justify-center">
              <div className="absolute w-6 h-6 p-2 rounded-full btn-gradient -top-6 custom-dot"></div>
              <div className="inline-block h-12 border-l-2 border-gray-400 border-dashed"></div>
            </div>
            <p className="text-sm text-white text-opacity-75 lg:text-lg">
              When we first meet each other, we'll ask you all about what you're
              trying to build. If we're a good fit for you, we'll recommend you
              what we think is the most efficient and scalable way to build out
              your project - free of charge.
            </p>
          </div>
          <div className="w-1/2 px-2 mb-12 lg:w-1/4 lg:px-4 lg:mb-0">
            <div className="relative flex justify-center">
              <div className="absolute w-6 h-6 p-2 rounded-full btn-gradient -top-6 custom-dot"></div>
              <div className="inline-block h-12 border-l-2 border-gray-400 border-dashed"></div>
            </div>
            <p className="text-sm text-white text-opacity-75 lg:text-lg">
              As early as a day later, we get to work! From our talented teams,
              we select the senior developers that have the closest experience
              to what you'd like to build, and manage them and the development
              cycle for you.
            </p>
          </div>
          <div className="w-1/2 px-2 pl-16 mb-12 lg:w-1/4 lg:pl-2 lg:px-4 lg:mb-0">
            <div className="relative flex justify-center">
              <div className="absolute w-6 h-6 p-2 rounded-full btn-gradient -top-6 custom-dot"></div>
              <div className="inline-block h-12 border-l-2 border-gray-400 border-dashed"></div>
            </div>
            <p className="text-sm text-white text-opacity-75 lg:text-lg">
              You get full access from day one! Aside from your project manager,
              we set up a Slack channel for the project and add you to it,
              provide you with daily updates, and give you a 24/7 live link to
              the latest version we've built.
            </p>
          </div>
          <div className="w-1/2 px-2 mb-12 lg:w-1/4 lg:px-4 lg:mb-0">
            <div className="relative flex justify-center">
              <div className="absolute w-6 h-6 p-2 rounded-full btn-gradient -top-6 custom-dot"></div>
              <div className="inline-block h-12 border-l-2 border-gray-400 border-dashed"></div>
            </div>
            <p className="text-sm text-white text-opacity-75 lg:text-lg">
              Congratulations! You now have a production-ready best-in-class
              product written by some of the best developers out there. Not
              "kinda" what you wanted, not a bug-ridden version, but a stable,
              professional product that's immediately ready to launch.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
