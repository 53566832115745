import React, { FC, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { ITeamDetail } from '../../utils/Interfaces';
import { ReactComponent as Close } from '../../assets/images/closeDark.svg'

const Model: FC<ITeamDetail> = ({ data, open, setOpen }) => {
    const [isActive, setIsActive] = useState({ overview: true, skills: true, project: true });
    const [keys, setKeys] = useState<string[]>([])

    useEffect(() => {
        if (data) {
            setKeys(Object.keys(data))
        }
    }, [data])
    const handleClose = () => {
        setOpen(false)
        setIsActive({ overview: true, skills: false, project: false })
    }
    Modal.setAppElement('#root');
    return (
        <Modal
            closeTimeoutMS={500}
            isOpen={open}
            onRequestClose={handleClose}
            className="p-0 bg-transparent absolute w-full max-w-5xl inset-y-5 lg:inset-y-10 overflow-auto outline-none rounded"
            overlayClassName="fixed inset-0 bg-white bg-opacity-25 flex justify-center items-center"
            contentLabel="Example Modal"
        >
            <div className="bg-white h-full p-5 lg:p-24 mx-5 lg:mx-0 overflow-auto">
                <div className="absolute top-5 right-5 cursor-pointer" onClick={handleClose}>
                    <Close />
                </div>
                <div>
                    <div className="border-b mb-16 py-3 border-black border-opacity-70">
                        <h5 className="text-3xl lg:text-5xl text-black text-opacity-75 font-ProximaNova font-semibold">{data?.name}</h5>
                        <p className="text-lg lg:text-xl text-gradiant font-ProximaNova font-semibold">{data?.posistion}</p>
                    </div>

                    <div className="border-b mb-16 border-black border-opacity-70">
                        <div className="flex items-center justify-between cursor-pointer mb-7" onClick={() => setIsActive(({ ...isActive, overview: !isActive.overview }))}>
                            <h4 className="text-base font-NoirMedium text-customGrayColor uppercase">{keys[3]}</h4>
                            <div className={isActive.overview ? 'transform rotate-180' : 'transform rotate-0'}>
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 0L7 5L12 0L14 1L7 8L0 1L2 0Z" fill="#80FCFD" fillOpacity="0.85" />
                                </svg>
                            </div>
                        </div>
                        {isActive.overview && <p className="text-xl font-NoirLight text-black pb-10 text-opacity-90">{data?.overview.description}</p>}
                    </div>

                    <div className="border-b mb-16 border-black border-opacity-70">
                        <div className="flex items-center justify-between cursor-pointer mb-7" onClick={() => setIsActive(({ ...isActive, skills: !isActive.skills }))}>
                            <h4 className="text-base font-NoirMedium text-customGrayColor uppercase">{keys[4]}</h4>
                            <div className={isActive.skills ? 'transform rotate-180' : 'transform rotate-0'}>
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 0L7 5L12 0L14 1L7 8L0 1L2 0Z" fill="#80FCFD" fillOpacity="0.85" />
                                </svg>
                            </div>
                        </div>
                        {isActive.skills &&
                            <div className="pb-10">
                                {Object.keys(data.skills).map((item, key) => {
                                    return (
                                        <div className="flex flex-wrap mb-3" key={key}>
                                            <p className="lg:w-1/3 max-w-33 text-xl font-NoirLight text-black text-opacity-90">{item}</p>
                                            <p className="lg:w-2/3 pl-5 lg:pl-0 text-xl font-NoirLight text-black text-opacity-90">{data.skills[item]}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>

                    <div className="border-b mb-16 border-black border-opacity-70">
                        <div className="flex items-center justify-between cursor-pointer mb-7" onClick={() => setIsActive(({ ...isActive, project: !isActive.project }))}>
                            <h4 className="text-base font-NoirMedium text-customGrayColor uppercase">{keys[5]}</h4>
                            <div className={isActive.project ? 'transform rotate-180' : 'transform rotate-0'}>
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 0L7 5L12 0L14 1L7 8L0 1L2 0Z" fill="#80FCFD" fillOpacity="0.85" />
                                </svg>
                            </div>
                        </div>
                        {isActive.project &&
                            <>
                                {data['EXAMPLE PROJECTS'].map((value, key) => {
                                    return (
                                        <div className="pb-10" key={key}>
                                            {Object.keys(value).map((item, key) => {
                                                return (
                                                    <div className="flex flex-wrap mb-3" key={key}>
                                                        <p className="lg:w-1/3 max-w-33 text-xl font-NoirLight text-black text-opacity-90">{item}</p>
                                                        <p className="lg:w-2/3 pl-5 lg:pl-0 text-xl font-NoirLight text-black text-opacity-90">{value[item]}</p>
                                                    </div>)
                                            })}
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Model;
